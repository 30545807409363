import ApiIntegrface from "./api"

export function getSamples(callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/samples'
  api.get(url, null, callback, null, null, null)
}

export function getSample(sampleId, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/samples/' + sampleId
  api.get(url, null, callback,null, null, null, false)
}

export function addSample(payload, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/samples'
  api.post(url, payload, callback, null, successMessage)
}


export function updateSample(payload, sampleId,callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/samples/'+ sampleId
  api.put(url, payload, callback, null, successMessage)
}

export function deleteSample(sampleId, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/samples/' + sampleId
  api.delete(url, null, callback, null, successMessage)
}

export function clearCache(sampleId, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/samples/' + sampleId + '/cache'
  api.delete(url, null, null, null, successMessage)
}

export function getSampleStatuses(callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/samplestatuses'
  api.get(url, null, callback, null, null, null)
}
export function getSampleTechnologies(callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/sampletechnologies'
  api.get(url, null, callback, null, null, null)
}

