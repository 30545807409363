<template>
  <div class="my-4 mx-8 d-flex flex-wrap justify-center" >
    <v-card v-for="item in datasets" :key="item.id" class="mx-4" min-width="700px" >
      <v-card-title class="py-1 mb-2  white--text" :class="item.datasetType.name == 'SV' ? 'primary':'secondary'"><v-icon color="white" class="mr-2 ml-0 pl-0">mdi-file-table-box-outline</v-icon>{{item.datasetType.name}}</v-card-title>
      <v-card-text>
        <v-row >
            <v-col cols="3" >
              <div class="caption primary--text" >Status</div>
              <div class="subtitle-2 pl-2"> {{item.status.name}}</div>
            </v-col>
            <v-col cols="5" >
              <div class="caption primary--text" >Imported at</div>
              <div class="subtitle-2 pl-2"> {{item.importedAt}}</div>
            </v-col>
            <v-col cols="3" >
              <div class="caption primary--text" >Rows/Columns</div>
              <div class="subtitle-2 pl-2"> {{item.recordCount}}/{{item.columnCount}}</div>
            </v-col>
            <v-col cols="1">
              <router-link :to="{name:'datasetInfo', params:{id: item.id}}" class="text-decoration-none" >
                <v-tooltip right color="primary">
                  <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        large
                        color="primary"
                        v-on="on"
                      >
                        <v-icon class="mr-2">mdi-cog-outline</v-icon>
                      </v-btn>
                  </template>
                  <span> Manage  <strong>{{item.datasetType.name}}</strong> </span>
              </v-tooltip>
              </router-link>
            </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import { getDatasetsBySample } from '@/api/dataset.js'
  export default {
    name:'SampleDatasetList',
    props :{
      sampleId: {
        type: Number,
        default: null
      }
    },
    data: () => ({
      headers: [
        { text: 'TYPE', value: 'datasetType.name', cellClass:"font-weight-bold" },
        { text: 'STATUS', value: 'status.name' },
        { text: 'IMPORTED AT', value: 'importedAt' },
        { text: 'ROWS', value: 'fileName'},
        { text: 'COLUMNS', value: 'fileSize'  },
        { text: 'MANAGE', divider:true, value: 'actions', width:'90px' },
      ],
      datasets: [],
      editedIndex: -1,
      selectedDataset: {
        name: '',
        datasetType: 1
      },
      defaultItem: {
        name: '',
        datasetType: 1
      },
    }),
    created () {
      this.loadDatasets()
    },
    methods: {
      loadDatasets(){
        const _this = this
        getDatasetsBySample(this.sampleId, function(res){
          _this.datasets = res.map(ds => {
            ds.uploadedAt = (ds.uploadedAt !== null ? new Date(ds.uploadedAt).toLocaleString() : '')
            ds.importedAt = (ds.importedAt !== null ? new Date(ds.importedAt).toLocaleString() : '')
            if ( ds.fileSize > 1024*1024 ){
              ds.fileSize =  Math.round((ds.fileSize/1024/1024)*100)/100 + ' MB'
            } else if ( ds.fileSize > 1024 ){
              ds.fileSize =  Math.round((ds.fileSize/1024)*10)/10 + ' KB'
            } else if (ds.fileSize !== null ){
              ds.fileSize = ds.fileSize + ' Bytes'
            }
            return ds
          }).sort((a,b) => a.datasetType.id - b.datasetType.id)
        })
      }
    },
  }
</script>